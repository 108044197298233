import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateUserById } from "../../../actions/authActions";
import moment from "moment-timezone";
import { toast } from "react-hot-toast";

const AdminSettingView = () => {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  let timeZoneArr = [];
  const usStates = moment.tz.zonesForCountry("US");
  usStates.forEach((e) => {
    const structure = moment.tz(e).format();
    // timeZoneArr.push({state: e, utc: "(UTC "+ structure.substring(19,22) +")"})
    timeZoneArr.push({
      e,
      tz: e + " (UTC " + structure.substring(19, 22) + ")",
    });
  });
  // console.log(timeZoneArr, "usStatesusStatesusStatesusStatesusStatesusStates")
  const handlePhone = (fieldName) => (event) => {
    // console.log(event.target.value.length, event.target.value, event.target.value !== "(", values.phone.substring(4,values.phone.length), values.phone.length, values.phone.substring(0,3))
    if (event.target.value.length === 1) {
      if (event.target.value !== "(") {
        setValues({
          ...values,
          [fieldName]: "(" + event.target.value,
        });
      }
    } else if (event.target.value.length === 4) {
      if (event.target.value !== ")") {
        setValues({
          ...values,
          [fieldName]: event.target.value + ") ",
        });
      }
    } else if (event.target.value.length === 9) {
      if (event.target.value !== "-") {
        setValues({
          ...values,
          [fieldName]: event.target.value + "-",
        });
      }
    } else {
      setValues({
        ...values,
        [fieldName]: event.target.value,
      });
    }
  };

  const alphabets = [
    "a",
    "b",
    "c",
    "d",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
    "A",
    "B",
    "C",
    "D",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
    "+",
    "-",
    "/",
    "*",
    "!",
    "@",
    "#",
    "$",
    "%",
    "^",
    "&",
    "_",
    "(",
    ")",
  ];
  const [values, setValues] = useState({
    address: "",
    state: "",
    // country: "",
    // city: "",
    pinCode: "",
    phone: "",
    // website: "",
    timeZoneKey: "",
    password: "",
    passwordConfirm: "",
  });
  useEffect(() => {
    if (auth.user !== null) {
      // const no = auth.user.phone
      // let noArr = no.split(" ")
      // console.log(noArr, "let noArr = no.spli")
      setValues({
        address: auth.user.address,
        state: auth.user.state,
        // country: auth.user.country,
        // city: auth.user.city,
        pinCode: auth.user.pinCode,
        phone: auth.user.phone,
        timeZoneKey: auth.user.timeZoneKey ? auth.user.timeZoneKey : "",
      });
      // console.log(moment.tz(auth.user.timeZone).format(),"auth.user.timeZone", auth.user.timeZone.split(' (UTC ')[1].substring(0,3) === moment.tz(auth.user.timeZoneKey).format().substring(19,22), auth.user.timeZone.split(' (UTC ')[1].substring(0,3) , moment.tz(auth.user.timeZoneKey).format().substring(19,22))
      if (
        auth.user.timeZone.split(" (UTC ")[1].substring(0, 3) !==
        moment.tz(auth.user.timeZoneKey).format().substring(19, 22)
      ) {
        dispatch(
          updateUserById({
            userId: auth.user.id,
            timeZone:
              auth.user.timeZoneKey +
              " (UTC " +
              moment.tz(auth.user.timeZoneKey).format().substring(19, 22) +
              ")",
          })
        );
      }
    }
  }, [auth.user]);
  const handleChange = (fieldName) => (event) => {
    setValues({
      ...values,
      [fieldName]: event.target.value,
    });
    // passwordChk()
  };
  const userData = {
    userId: auth.user.id,
    address: values.address,
    state: values.state,
    // country: values.country,
    // city: values.city,
    pinCode: values.pinCode,
    phone: values.phone,
    password: values.password,
    timeZoneKey: values.timeZoneKey,
    timeZone:
      values.timeZoneKey +
      " (UTC " +
      moment.tz(values.timeZoneKey).format().substring(19, 22) +
      ")",
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (values.password === values.passwordConfirm) {
      for (let key of Object.keys(userData)) {
        if (userData[key] === "" || userData[key] === "+") {
          delete userData[key];
        }
      }
      dispatch(updateUserById(userData));
    } else {
      toast.error("Password, don't match");
      // alert("Password, don't match");
    }
  };
  // console.log(timeZoneArr)
  // console.log(values.timeZone, "tttiiimmmmeeeeeeezzzzzooonnnneeeeeeeeeeee")
  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="row">
            {/* <div className="col-md-8 offset-md-2"> */}
            {/* <div className="page-header">
                <div className="row">
                  <div className="col-sm-12">
                    <h3 className="page-title">Settings</h3>
                  </div>
                </div>
              </div> */}
            {/* <form onSubmit={handleSubmit}>
              {auth.user && (
                <> */}
            <div classname="row">
              <div className="container">
                <div classname="col-xl-12 col-sm-12 col-12">
                  <div classname="card rounded-3">
                    <div classname="card-header rounded-3 bg-success-dark">
                      <div classname="d-flex justify-content-between align-items-center">
                        <h5 classname="card-titl text-white">Settings</h5>
                      </div>
                    </div>
                    <div classname="card-body">
                      <form classname="row" onSubmit={handleSubmit}>
                        <div classname="col-md-6 mb-3">
                          <label for="companyName" classname="form-label">
                            Company Name <span classname="text-denger">*</span>
                          </label>
                          {auth.company && (
                            <input
                              type="text"
                              classname="form-control bg-light"
                              placeholder=""
                              id="companyName"
                              value={auth.company[0].company.companyName}
                              disabled
                            />
                          )}
                        </div>
                        <div classname="col-md-6 mb-3">
                          <label for="contactPerson" classname="form-label">
                            Contact Person
                            <span classname="text-denger">*</span>
                          </label>
                          <input
                            type="text"
                            classname="form-control bg-light"
                            placeholder=""
                            id="contactPerson"
                            value={
                              auth.user.firstName + " " + auth.user.lastName
                            }
                            disabled
                          />
                        </div>
                        <div classname="col-md-12 mb-3">
                          <label for="Address" classname="form-label">
                            Address<span classname="text-denger">*</span>
                          </label>
                          <input
                            type="text"
                            classname="form-control bg-light"
                            placeholder=""
                            id="Address"
                            value={values.address}
                            onChange={handleChange("address")}
                          />
                        </div>
                        <div classname="col-md-4 mb-3">
                          <label for="State/Province" classname="form-label">
                            State/Province
                            <span classname="text-denger">*</span>
                          </label>
                          <input
                            type="text"
                            classname="form-control bg-light"
                            placeholder=""
                            id="State/Province"
                            value={values.state}
                            onChange={handleChange("state")}
                          />
                        </div>
                        <div classname="col-md-4 mb-3">
                          <label for="Postalcode" classname="form-label">
                            Postal Code<span classname="text-denger">*</span>
                          </label>
                          <input
                            type="number"
                            classname="form-control bg-light"
                            placeholder=""
                            id="Postalcode"
                            onKeyDown={(e) => {
                              if (alphabets.includes(e.key)) {
                                e.preventDefault();
                              }
                            }}
                            className="form-control"
                            value={values.pinCode}
                            onChange={handleChange("pinCode")}
                          />
                        </div>
                        <div classname="col-md-4 mb-3">
                          <label for="Timezone" classname="form-label">
                            Time Zone<span classname="text-denger">*</span>
                          </label>
                          <select
                            className="form-select bg-light"
                            // autoComplete='false'
                            // required
                            value={values.timeZoneKey}
                            onChange={handleChange("timeZoneKey")}
                          >
                            <option value="">Select</option>
                            {timeZoneArr.map((tz, ind) => {
                              return (
                                <option value={tz.e} key={ind}>
                                  {tz.tz}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        <div classname="col-md-6 mb-3">
                          <label for="email" classname="form-label">
                            Email<span classname="text-denger">*</span>
                          </label>
                          <input
                            type="email"
                            classname="form-control bg-light"
                            placeholder=""
                            id="email"
                            value={auth.user.email}
                            disabled
                          />
                        </div>
                        <div classname="col-md-6 mb-3">
                          <label for="Phonenumber" classname="form-label">
                            Phone Number<span classname="text-denger">*</span>
                          </label>
                          <input
                            type="tel"
                            classname="form-control bg-light"
                            id="Phonenumber"
                            minLength="14"
                            maxLength="14"
                            required
                            // pattern="([0-9]{3})-[0-9]{3}-[0-9]{4}"
                            onKeyDown={(e) => {
                              if (alphabets.includes(e.key)) {
                                e.preventDefault();
                              }
                              if (
                                e.code === "Backspace" &&
                                values.phone.length === 6
                              ) {
                                setValues({
                                  ...values,
                                  phone: values.phone.substring(0, 4),
                                });
                              }
                              if (
                                e.code === "Backspace" &&
                                values.phone.length === 2
                              ) {
                                setValues({
                                  ...values,
                                  phone: "",
                                });
                              }
                              if (
                                e.code === "Backspace" &&
                                values.phone.length === 10
                              ) {
                                setValues({
                                  ...values,
                                  phone: values.phone.substring(0, 9),
                                });
                              }
                            }}
                            name="phone"
                            value={values.phone}
                            onChange={handlePhone("phone")}
                            placeholder="(123) 456-7890"
                            autocomplete="new-mobileNunmber"
                          />
                        </div>
                        <div classname="col-md-6 mb-3">
                          <label for="password" classname="form-label">
                            Change Password
                            <span classname="text-denger">*</span>
                          </label>
                          <input
                            type="password"
                            classname="form-control bg-light"
                            placeholder=""
                            id="password"
                            value={values.password || ""}
                            onChange={handleChange("password")}
                          />
                        </div>
                        <div classname="col-md-6 mb-3">
                          <label for="password" classname="form-label">
                            Confirm Password
                            <span classname="text-denger">*</span>
                          </label>
                          <input
                            type="password"
                            classname="form-control bg-light"
                            placeholder=""
                            id="password"
                            value={values.passwordConfirm || ""}
                            onChange={handleChange("passwordConfirm")}
                          />
                        </div>
                        <div classname="col-md-6">
                          <button classname="btn btn-success btn-lg px-5">
                            Save
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AdminSettingView;
