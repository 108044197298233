import React, { useState, forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createLeave, getLeaveByUserId } from "../../../actions/leaveActions";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
// import moment from 'moment';

const AddLeaveModal = ({ showDropDown, openDropDown }) => {
  // const [showdrop, setshowdrop] = useState("")
  // useEffect(() =>{
  //    setshowdrop(showDropDown)
  // }, [showDropDown])

  const auth = useSelector((state) => state.auth);
  const leave = useSelector((state) => state.leave);
  const dispatch = useDispatch();
  const [values, setValues] = useState({
    index: "0",
  });
  const handleChange = (fieldName) => (event) => {
    setValues({
      ...values,
      [fieldName]: event.target.value,
    });
  };
  let val = 0;

  const [data, setData] = useState({
    userId: "",
    leaveType: "",
    from: "",
    to: "",
    leaveReason: "",
    companyId: "",
    dated: "",
  });
  const handleChangeData = (fieldName) => (event) => {
    setData({
      ...data,
      [fieldName]: event.target.value,
    });
  };
  // console.log(values)
  const [fromDate, setFromDate] = useState(
    new Date(moment().format("YYYY-MM-DD"))
  );
  const [toDate, setToDate] = useState(new Date(moment().format("YYYY-MM-DD")));
  // const [mxDt, setMxDt] = useState(new Date());
  const mxDt = new Date(fromDate);
  const From = forwardRef(({ value, onClick }, ref) => (
    <div className="cal-icon" onClick={onClick} ref={ref}>
      <input
        className="form-control floating datetimepicker"
        type="text"
        value={value}
      />
    </div>
  ));
  const To = forwardRef(({ value, onClick }, ref) => (
    <div className="cal-icon" onClick={onClick} ref={ref}>
      <input
        className="form-control floating datetimepicker"
        type="text"
        value={value}
      />
    </div>
  ));
  const leaveData = {
    userId: auth.user.id,
    leaveType:
      leave.leaveAnnualStatus !== null
        ? leave.leaveAnnualStatus[parseInt(values.index)].leaveCategory.id
        : 0,
    from: fromDate.toISOString(),
    to: toDate.toISOString(),
    leaveReason: data.leaveReason,
    companyId: auth.user.companyId,
    dated: moment().format("YYYY-MM-DD"),
  };
  // console.log(leaveData)
  const leaveListData = async (e) => {
    dispatch(getLeaveByUserId({ userId: auth.user.id }));
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    dispatch(createLeave(leaveData));
    leaveListData();
    openDropDown();
  };
  return (
    <>
      <div
        className={`modal-backdrop fade ${showDropDown ? "show" : "hide"}`}
      ></div>
      <div
        id="add_leave"
        className={`modal custom-modal fade ${showDropDown ? "show" : "hide"}`}
        role="dialog"
        onClick={(e) => {
          // Check if the click occurred outside the modal content
          if (e.target === e.currentTarget) {
            openDropDown(); // Call your close modal function
          }
        }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Leave</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => openDropDown()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label>
                    Leave Type <span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-select"
                    autoComplete="false"
                    value={values.index}
                    onChange={handleChange("index")}
                  >
                    {/* <option value=''>Select Leave Type</option> */}
                    {leave.leaveAnnualStatus &&
                      leave.leaveAnnualStatus.length > 0 &&
                      leave.leaveAnnualStatus.map((current, i) => {
                        return (
                          <>
                            <option value={val++}>
                              {current.leaveCategory.name}
                            </option>
                          </>
                        );
                      })}
                  </select>
                </div>
                <div className="form-group">
                  <label>
                    From <span className="text-danger">*</span>
                  </label>
                  {/* <div className="cal-icon">
                              <input className="form-control datetimepicker" type="text" />
                           </div> */}
                  <DatePicker
                    selected={fromDate}
                    onChange={(date) => setFromDate(date)}
                    customInput={<From />}
                  />
                </div>
                <div className="form-group">
                  <label>
                    To <span className="text-danger">*</span>
                  </label>
                  {/* <div className="cal-icon">
                              <input className="form-control datetimepicker" type="text" />
                           </div> */}
                  <DatePicker
                    selected={toDate}
                    minDate={fromDate}
                    maxDate={mxDt.setDate(
                      fromDate.getDate() +
                        leave.leaveAnnualStatus[parseInt(values.index)]
                          .remainingLeavesDays -
                        1
                    )}
                    onChange={(date) => setToDate(date)}
                    customInput={<To />}
                  />
                </div>
                {/* <div className="form-group">
                           <label>Number of days <span className="text-danger">*</span></label>
                           <input className="form-control" readOnly type="text" />
                        </div> */}
                <div className="form-group">
                  <label>
                    Remaining Leaves <span className="text-danger">*</span>
                  </label>
                  {values.index === "" ? (
                    <input
                      className="form-control"
                      readOnly
                      value="Please Select Leave Type"
                      type="text"
                    />
                  ) : (
                    <input
                      className="form-control"
                      readOnly
                      value={
                        leave.leaveAnnualStatus[parseInt(values.index)]
                          .remainingLeavesDays
                      }
                      type="text"
                    />
                  )}
                </div>
                <div className="form-group">
                  <label>
                    Leave Reason <span className="text-danger">*</span>
                  </label>
                  <textarea
                    rows="4"
                    className="form-control"
                    type="text"
                    value={data.leaveReason}
                    onChange={handleChangeData("leaveReason")}
                    autoComplete="false"
                  ></textarea>
                </div>
                <div className="submit-section">
                  <button className="btn btn-primary submit-btn" type="submit">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AddLeaveModal;
