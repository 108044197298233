// import React, { useState } from 'react'
import React from "react";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
// import { tabTimeClock, tabRecentClockin, tabAttendance, tabTimeSheet, tabOvertime } from '../../../actions/tabActions'
import {
  tabTimeClock,
  tabRecentClockin,
  tabAttendance,
} from "../../../actions/tabActions";

const AdminTClk = () => {
  const myState = useSelector((state) => state.changeTheTab);
  // console.log("***********", myState.viewPayrollMenu)
  const dispatch = useDispatch();

  // const [showDropDown, setshowDropDown] = useState(false)
  // const openDropDown = () => {
  //     setshowDropDown((showDropDown) => !showDropDown)
  // }
  // console.log(showDropDown)

  return (
    <>
      {myState.viewTClk === "timeclock" ? (
        <>
          <div className="col-7 mitbsnav">
            <ul className="nav nav-pills nav-justified">
              <li className="nav-item active">
                <NavLink
                  to="#time-clock"
                  onClick={() => dispatch(tabTimeClock())}
                  className=""
                >
                  Time Clock
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="#recent-clock"
                  onClick={() => dispatch(tabRecentClockin())}
                  className=""
                >
                  Recent Clocked-in
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="#attendance"
                  onClick={() => dispatch(tabAttendance())}
                  className=""
                >
                  Attendance
                </NavLink>
              </li>
              {/* <li className="nav-item">
                        <NavLink to="#timesheet" onClick={() => dispatch(tabTimeSheet())} className="">Timesheet</NavLink>
                    </li> */}
              {/* <li className="nav-item">
                        <NavLink to="#overtime" onClick={() => dispatch(tabOvertime())} className="">Overtime</NavLink>
                    </li> */}
            </ul>
          </div>
          <div className="col-2 float-right ml-auto"></div>
        </>
      ) : myState.viewTClk === "recentlyclockedin" ? (
        <>
          <div className="col-7 mitbsnav">
            <ul className="nav nav-pills nav-justified">
              <li className="nav-item">
                <NavLink
                  to="#time-clock"
                  onClick={() => dispatch(tabTimeClock())}
                  className=""
                >
                  Time Clock
                </NavLink>
              </li>
              <li className="nav-item active">
                <NavLink
                  to="#recent-clock"
                  onClick={() => dispatch(tabRecentClockin())}
                  className=""
                >
                  Recent Clocked-in
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="#attendance"
                  onClick={() => dispatch(tabAttendance())}
                  className=""
                >
                  Attendance
                </NavLink>
              </li>
              {/* <li className="nav-item">
                        <NavLink to="#timesheet" onClick={() => dispatch(tabTimeSheet())} className="">Timesheet</NavLink>
                    </li> */}
              {/* <li className="nav-item">
                        <NavLink to="#overtime" onClick={() => dispatch(tabOvertime())} className="">Overtime</NavLink>
                    </li> */}
            </ul>
          </div>
          <div className="col-2 float-right ml-auto"></div>
        </>
      ) : myState.viewTClk === "attendance" ||
        myState.viewTClk === "attendanceSearch" ? (
        <>
          <div className="col-7 mitbsnav">
            <ul className="nav nav-pills nav-justified">
              <li className="nav-item">
                <NavLink
                  to="#time-clock"
                  onClick={() => dispatch(tabTimeClock())}
                  className=""
                >
                  Time Clock
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="#recent-clock"
                  onClick={() => dispatch(tabRecentClockin())}
                  className=""
                >
                  Recent Clocked-in
                </NavLink>
              </li>
              <li className="nav-item active">
                <NavLink
                  to="#attendance"
                  onClick={() => dispatch(tabAttendance())}
                  className=""
                >
                  Attendance
                </NavLink>
              </li>
              {/* <li className="nav-item">
                        <NavLink to="#timesheet" onClick={() => dispatch(tabTimeSheet())} className="">Timesheet</NavLink>
                    </li> */}
              {/* <li className="nav-item">
                        <NavLink to="#overtime" onClick={() => dispatch(tabOvertime())} className="">Overtime</NavLink>
                    </li> */}
            </ul>
          </div>
          <div className="col-2 float-right ml-auto"></div>
        </>
      ) : (
        // : myState.viewTClk === "timesheet" ?
        // <>
        // <div className="col-7 mitbsnav">
        //     <ul className="nav nav-pills nav-justified">
        //         <li className="nav-item">
        //             <NavLink to="#time-clock" onClick={() => dispatch(tabTimeClock())} className="">Time Clock</NavLink>
        //         </li>
        //         <li className="nav-item">
        //             <NavLink to="#recent-clock" onClick={() => dispatch(tabRecentClockin())} className="">Recent Clocked-in</NavLink>
        //         </li>
        //         <li className="nav-item">
        //             <NavLink to="#attendance" onClick={() => dispatch(tabAttendance())} className="">Attendance</NavLink>
        //         </li>
        //         <li className="nav-item active">
        //             <NavLink to="#timesheet" onClick={() => dispatch(tabTimeSheet())} className="">Timesheet</NavLink>
        //         </li>
        //         <li className="nav-item">
        //             <NavLink to="#overtime" onClick={() => dispatch(tabOvertime())} className="">Overtime</NavLink>
        //         </li>
        //         </ul>
        //         </div>
        //         <div className="col-2 float-right ml-auto">
        //             <NavLink to="#" className="btn add-btn" data-toggle="modal" data-target="#add_todaywork" onClick={openDropDown}><i className="fa fa-plus"></i> Add Today Work</NavLink>
        //         </div>
        //         {/* <AdminEmpTimeSheet/> */}
        //         <AdminEmpTimeSheetModal showDropDown={showDropDown} openDropDown={openDropDown}/>
        //             </>

        //    : myState.viewTClk === "overtime" ?
        //     <>
        //     <div className="col-7 mitbsnav">
        //         <ul className="nav nav-pills nav-justified">
        //             <li className="nav-item">
        //             <NavLink to="#time-clock" onClick={() => dispatch(tabTimeClock())} className="">Time Clock</NavLink>
        //             </li>
        //             <li className="nav-item">
        //                 <NavLink to="#recent-clock" onClick={() => dispatch(tabRecentClockin())} className="">Recent Clocked-in</NavLink>
        //             </li>
        //             <li className="nav-item">
        //                 <NavLink to="#attendance" onClick={() => dispatch(tabAttendance())} className="">Attendance</NavLink>
        //             </li>
        //             {/* <li className="nav-item">
        //                 <NavLink to="#timesheet" onClick={() => dispatch(tabTimeSheet())} className="">Timesheet</NavLink>
        //             </li> */}
        //             <li className="nav-item active">
        //                 <NavLink to="#overtime" onClick={() => dispatch(tabOvertime())} className="">Overtime</NavLink>
        //             </li>
        //             </ul>
        //             </div>
        //             <div className="col-2 float-right ml-auto">
        //                 <NavLink to="#" className="btn add-btn" data-toggle="modal" data-target="#add_overtime" onClick={openDropDown}><i className="fa fa-plus"></i> Add Overtime</NavLink>
        //             </div>
        //             <AdminOTModal showDropDown={showDropDown} openDropDown={openDropDown}/>
        // </>
        <div>Loading</div>
      )}
    </>
  );
};
export default AdminTClk;
