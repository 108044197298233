import React, { useEffect, useRef } from "react";
import AdminHeader from "../../components/adminComponents/AdminHeader";
import AdminSideBar from "../../components/adminComponents/AdminSideBar";

import "./App.css";

import "../../assetsAdmin/css/bootstrap.min.css";
import "../../assetsAdmin/css/bootstrap-datetimepicker.min.css";
import "../../assetsAdmin/css/dataTables.bootstrap4.min.css";
import "../../assetsAdmin/css/font-awesome.min.css";
import "../../assetsAdmin/css/fullcalendar.min.css";
import "../../assetsAdmin/css/jquery.circliful.css";
import "../../assetsAdmin/css/line-awesome.min.css";
import "../../assetsAdmin/css/morris.css";
import "../../assetsAdmin/css/select2.min.css";
import "../../assetsAdmin/css/style.css";
import "../../assetsAdmin/css/tagsinput.css";
import { useDispatch, useSelector } from "react-redux";
import {
  MESSAGE_RECEIVE_SUCCESS,
  NOTIFICATION_RECEIVE_SUCCESS,
  SOCKETADDRESS,
  SOCKET_CONNECTED,
  SOCKET_DISCONNECT,
} from "../../constants";
import { useState } from "react";
import io from "socket.io-client";
import RightSidebar from "./rightSidebar";
import { getNotificationsAction } from "../../actions/notificationActions";
import {
  adminDashboard,
  getStripeInovicesQuery,
} from "../../actions/adminActions";
import {
  allUsers,
  allUsersWithoutPaginate,
  lastId,
} from "../../actions/userActions";
import { monthlyAttendance } from "../../actions/clockActions";
import { companyById } from "../../actions/authActions";
import moment from "moment";
import {
  getAllLocationsWithUsers,
  getCompanyLocations,
} from "../../actions/locationActions";
import { getMailByUserId } from "../../actions/mailActions";

let socketInstance;
const AdminLayout = ({ children }) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  // const [socketConnected, setSocketConnected] = useState(false);

  useEffect(() => {
    if (socketInstance) {
      socketInstance.on("message recieved", (newMessageRecieved) => {
        console.log("message received==>", newMessageRecieved);

        dispatch({
          type: MESSAGE_RECEIVE_SUCCESS,
          payload: newMessageRecieved,
        });

        // const lastChildElement = scrollRef.current?.lastElementChild;
        // lastChildElement?.scrollIntoView({ behavior: "smooth" });
        // if (
        //   (!selectedChatCompare || selectedChatCompare._id) !==
        //   newMessageRecieved.chatId._id
        // ) {
        //   if (!notifications.includes(newMessageRecieved)) {
        //     dispatch(setNotifications([newMessageRecieved, ...notifications]));
        //   }
        // } else {
        //   setMessages([...messages, newMessageRecieved]);
        // }
        // dispatch(fetchChats());
      });

      socketInstance.on("notification recieved", (notificationReceived) => {
        console.log("notification received==>", notificationReceived);

        dispatch({
          type: NOTIFICATION_RECEIVE_SUCCESS,
          payload: notificationReceived,
        });
        notificationRef.current.play();
      });
    }
  }, [socketInstance]);

  const notificationRef = useRef();
  const adminDashboardData = async (e) => {
    dispatch(
      adminDashboard({
        userId: auth.user.id,
        companyId: auth.user.companyId,
        date: moment().format("YYYY-MM-DD"),
      })
    );
  };
  const mailData = async () => {
    dispatch(getMailByUserId({ userId: auth.user.id }));
  };
  const allUsersDataWithoutPagination = async () => {
    dispatch(allUsersWithoutPaginate({ companyId: auth.user.companyId }));
  };
  const locationData = async (e) => {
    dispatch(getCompanyLocations({ companyId: auth.user.companyId }));
    dispatch(getAllLocationsWithUsers({ companyId: auth.user.companyId }));
  };
  const companyData = async () => {
    dispatch(companyById({ companyId: auth.user.companyId }));
  };
  // const allUsersData = async ( field, order) => {
  //   dispatch(allUsers({ companyId: auth.user.companyId }, field, order));
  // };
  const attendanceData = async () => {
    dispatch(
      monthlyAttendance({
        companyId: auth.user.companyId,
        date: moment().format("YYYY-MM-DD"),
      })
    );
  };
  // const invoiceData = async () => {
  //   dispatch(getStripeInovicesQuery({ companyId: auth.user.companyId }));
  // };
  const lastIdData = async () => {
    dispatch(lastId({ companyId: auth.user.companyId }));
  };
  // const usersShiftsData = async () =>{
  //     dispatch(getShiftAllUsers({companyId: auth.user.companyId, date: moment().format('YYYY-MM-DD')}))
  // }

  useEffect(() => {
    adminDashboardData();
    // allUsersData();
    companyData();
    attendanceData();
    locationData();
    // invoiceData();
    mailData();
    allUsersDataWithoutPagination();
    lastIdData();
    dispatch(getNotificationsAction(auth.user.id));

    socketInstance = io(SOCKETADDRESS);

    socketInstance.emit("setup", auth.user.id);
    socketInstance.on("connected", () => {
      // setSocketConnected(true);
      dispatch({
        type: SOCKET_CONNECTED,
        payload: { socketInstance, socketConnected: true },
      });
      // console.log()
    });

    // usersShiftsData()

    return () => {
      socketInstance.disconnect();
      dispatch({
        type: SOCKET_DISCONNECT,
        payload: { socketInstance: null, socketConnected: false },
      }); // socket.off("login");
    };
  }, []);
  return (
    <>
      <AdminHeader />
      <AdminSideBar />
      {/* <div className="main-wrapper">
        <div className="page-wrapper">
          <div className="content container-fluid"> */}
      {children}
      {/* <AdminTittle admin/>
                        <AdminDashboardCard />
                        <ShiftTime/>
                        <EmployeeDetails/> */}
      {/* </div>
        </div>
      </div> */}
      {/* <RightSidebar opensidedrawer={true} /> */}

      <audio src="/notification.wav" ref={notificationRef}></audio>
    </>
  );
};
export default AdminLayout;
